
export function setupFlash(){
  let flashEl = document.getElementById('app-flash')
  let flashIcon = flashEl.querySelector("[data-flash-icon]")
  let pendingMessages = []

  flashEl.addEventListener('transitionend', (ev) => {
    if(ev.target.classList.contains('shown')){
      flashEl.hideTimer = setTimeout(() => {
        flashEl.classList.remove('shown')
      }, 5000)
    }else{
      if(pendingMessages.length){
        showFlash()
      }
    }
  })

  function showFlash(){
    if(!pendingMessages.length){return}
    let message = pendingMessages.splice(0, 1)[0]
    flashEl.querySelector('h5').innerHTML = message.message
    if(message.icon){
      flashIcon.classList.add('bg-' + message.icon)
      flashIcon.classList.replace('hidden', 'block')
    }else{
      flashIcon.classList.replace('block', 'hidden')
    }
    flashEl.classList.add('shown')
  }

  document.addEventListener('flash.show', (ev) => {
    pendingMessages.push(ev.detail)
    if(!flashEl.classList.contains('shown')){
      showFlash()      
    }
  })

  if(window.pendingFlash){
    setTimeout(() => {
      let ev = new CustomEvent("flash.show", {detail: window.pendingFlash})
      document.dispatchEvent(ev)
      window.pendingFlash = null
    }, 500)
  }
}

export function flash(message, options){
  console.log({detail: Object.assign({message: message}, (options || {}))})
  let ev = new CustomEvent("flash.show", {detail: Object.assign({message: message}, (options || {}))})
  document.dispatchEvent(ev)
}