import * as CookieConsent from 'vanilla-cookieconsent'

export function runCookieConsent(){
  var cookie = CookieConsent.run({
    categories: {
      necessary: {
        enabled: true, 
        readOnly: true
      }, 
    }, 
    onFirstConsent: function(){
      CookieConsent.hide()
    },
    language: {
      default: 'sv', 
      translations: {
        sv: {
          consentModal: {
            title: "", 
            description: "Pattern använder kakor i appen för att upplevelsen ska bli så bra som möjligt.",
            acceptAllBtn: "Godkänn och fortsätt", 
            footer: `
              <a href="/cookie-policy" target="_blank" class="cookie-policy-link">Läs mer om kakor i Pattern</a>
            `
          }
        }
      }
    }
  })
}